var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("setup-table", {
    attrs: {
      title: `Trip Type`,
      items: _vm.items,
      headers: _vm.headers,
      loading: _vm.loading,
      clearItem: !_vm.showForm || !_vm.editedItem.id,
    },
    on: {
      createItem: function ($event) {
        return _vm.createItem()
      },
      editItem: _vm.editItem,
      deleteItems: _vm.deleteItems,
    },
    scopedSlots: _vm._u(
      [
        _vm.showForm
          ? {
              key: "itemForm",
              fn: function () {
                return [
                  _c("trip-type-form", {
                    ref: "tripTypeForm",
                    attrs: {
                      value: _vm.editedItem,
                      tripCategories: _vm.tripCategories,
                      vehicleTypes: _vm.vehicleTypes,
                    },
                    on: {
                      submit: _vm.saveItem,
                      closeForm: _vm.closeForm,
                      formChanges: _vm.formChanges,
                    },
                  }),
                ]
              },
              proxy: true,
            }
          : null,
        {
          key: `item.active`,
          fn: function ({ item }) {
            return [
              _c("v-icon", { attrs: { small: "" } }, [
                _vm._v(_vm._s(item.active ? "mdi-check" : "mdi-close")),
              ]),
            ]
          },
        },
        {
          key: `item.category`,
          fn: function ({ item }) {
            return [
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(_vm._s(_vm.tripCategories[item.categoryId].name)),
              ]),
            ]
          },
        },
        {
          key: `item.costPerMile`,
          fn: function ({ item }) {
            return [
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(_vm._s(_vm.toMoney(item.costPerMile, 4))),
              ]),
            ]
          },
        },
        {
          key: `item.planningCost`,
          fn: function ({ item }) {
            return [
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(_vm._s(_vm.toMoney(item.planningCost))),
              ]),
            ]
          },
        },
        {
          key: `item.additionalCost`,
          fn: function ({ item }) {
            return [
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(_vm._s(_vm.toMoney(item.additionalCost))),
              ]),
            ]
          },
        },
        {
          key: `item.standardFee`,
          fn: function ({ item }) {
            return [
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(_vm._s(_vm.toMoney(item.standardFee))),
              ]),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }